import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import goldImg from "../../../assets/images/certificate/gold.jpg"
import silverImg from "../../../assets/images/certificate/silver.jpg"
import bronzImg from "../../../assets/images/certificate/bronz.jpg"
import cert1 from "../../../assets/images/certificate/cert1.jpg"

const CertificateModal = props => {
  const { isOpen, toggle, studentData, allSchoolData } = props
  const printRef = useRef(null);

  const getImage = () => {
    if (studentData.avg >= 29) return goldImg
    if (studentData.avg >= 27) return silverImg
    if (studentData.avg >= 25) return bronzImg
    return cert1
  }

  const downloadPDF = async () => {
    const content = printRef.current; // Access the content using ref
    if (!content) return;

    // Convert the content to canvas
    const canvas = await html2canvas(content, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    
    const imgWidth = canvas.width / 5; // Convert canvas width to mm
    const imgHeight = canvas.height / 5;

    // Generate the PDF
    const pdf = new jsPDF("landscape", "mm", [imgWidth, imgHeight]);
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    const name = `certificate-${studentData.stdName}.pdf`;
    pdf.save(name);
  };
  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      toggle={toggle}
      className="modal-fullscreen"
    >
      <div className="modal-content m-auto">
        <ModalHeader toggle={toggle} dir="rtl">
          {`مدرسة ${allSchoolData.schoolName} / ${allSchoolData.learningType} / ${allSchoolData.gender}`}
        </ModalHeader>
        <ModalBody className="">
          {/* Button to download pdf */}
          <div className="m-auto" style={{ width: "943px" }}>
            <button type="button" className="btn btn-primary  btn-label m-2" onClick={downloadPDF} >
              <i className="bx bx-download label-icon"></i>تحميل الشهادة
            </button>
          </div>
          {/* layout to print */}
          <div
            className="border border-2 position-relative m-auto text-center"
            style={{ width: "943px" }} ref={printRef}
          >
            <div
              className="position-absolute bg-white pt-1"
              style={{
                top: "37%",
                right: "5%",
                width: "90%",
                fontSize: "26px",
              }}
              dir="rtl"
            >
              <p className=" fw-bold">
                <span style={{ color: "#c00000" }}>للطالب[ة] المتميز[ة]</span>
                {studentData !== "" && studentData.stdName}
              </p>
              <p className="fw-bold">
                <span style={{ color: "#c00000" }}>مدرسة </span>
                {allSchoolData !== "" && allSchoolData.schoolName}
              </p>
            </div>
            <img src={getImage()} />
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default CertificateModal
