import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/school-logo-text.png"

const WelcomeComp = ({schoolNewData}) => {

  const [schlGirlsCount, setSchlGirlsCount] = useState(0)
  const [schlBoysCount, setSchlBoysCount] = useState(0)

  useEffect(() => {
    let girls = 0;
    let boys = 0;
    schoolNewData.map(school => {
      if (school.gender === "بنين") {
        boys += 1
      }
      if (school.gender === "بنات") {
        girls += 1
      }
    })
    setSchlGirlsCount(girls)
    setSchlBoysCount(boys)
  }, [schoolNewData])
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
          <Row className="bg-primary bg-soft text-end">
            <Col xs="3">
              {/* <div className="text-primary p-3">
                <h5 className="text-primary">!مرحبا بعودتك </h5>
                <p>مسابقة روضة بنت جاسم</p>
              </div> */}
            </Col>
            <Col xs="9" className="">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        <div className="bg-primary bg-soft">
        </div>
        <CardBody className="pt-0">
          <Row>

            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4 rounded-circle bg-primary" style={{ padding: "12px"}}>
              <div className="avatar-sm rounded-circle bg-primary border border-2 border-white">
                {/* <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                /> */}
                <span className="avatar-title font-size-16">45</span>
              </div>
              </div>
              <h5 className="font-size-15 text-center">عدد المدارس الكلي</h5>
              {/* <p className="text-muted mb-0 text-truncate">45</p> */}
            </Col>
            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6" className="text-primary text-center">
                    <h5 className="font-size-15">{schlBoysCount}</h5>
                    <p className="mb-0">عدد مدارس الاولاد</p>
                  </Col>
                  <Col xs="6" className="text-pink text-center">
                    <h5 className="font-size-15">{schlGirlsCount}</h5>
                    <p className="mb-0">عدد مدارس البنات</p>
                  </Col>
                </Row>
                {/* <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
