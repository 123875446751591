import React, { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"

const Apaexlinecolumn = ({ dataColors, schoolNewData, periodType }) => {
  const [studentsList, setStudentsList] = useState([])
  const [studentsBoyList, setStudentsBoyList] = useState([])
  const [studentsGirlsList, setStudentsGirlsList] = useState([])
  const [topTenFirstDeg, setTopTenFirstDeg] = useState([])
  const [topTenSecondDeg, setTopTenSecondDeg] = useState([])
  const [topTenAvgDeg, setTopTenAvgDeg] = useState([])
  const [topTenName, setTopTenName] = useState([])

  useEffect(() => {
    if (periodType === "all") {
      let students = []
      schoolNewData.map(school => students.push(...school.stdsDetails))
      const sortedStudents = [...students].sort((a, b) => b.avg - a.avg)
      setStudentsList(sortedStudents)
    } else if (periodType === "boys") {
      let students = []
      schoolNewData.map(school => school.gender === "بنين" && students.push(...school.stdsDetails))
      const sortedStudents = [...students].sort((a, b) => b.avg - a.avg)
      setStudentsList(sortedStudents)
    } else if (periodType === "girls") {
      let students = []
      schoolNewData.map(school => school.gender === "بنات" && students.push(...school.stdsDetails))
      const sortedStudents = [...students].sort((a, b) => b.avg - a.avg)
      setStudentsList(sortedStudents)
    }
  }, [schoolNewData, periodType])

  useEffect(() => {
    if (studentsList.length) {
      const topTenFirstDeg = studentsList.slice(0, 10)
      const firstDegList = []
      topTenFirstDeg.map(std => firstDegList.push(std.degreeTrm1))
      setTopTenFirstDeg(firstDegList)

      const secondDegList = []
      topTenFirstDeg.map(std => secondDegList.push(std.degreeTrm2))
      setTopTenSecondDeg(secondDegList)

      const avgList = []
      topTenFirstDeg.map(std => avgList.push(std.avg))
      setTopTenAvgDeg(avgList)

      const nameList = []
      topTenFirstDeg.map(std => nameList.push(std.stdName))
      setTopTenName(nameList)
    }
  }, [studentsList])

  const apaexlineColumnColors = getChartColorsArray(dataColors)
  const series = [
    {
      name: "الاختبار الثاني",
      data: topTenSecondDeg.length ? topTenSecondDeg : [],
    },
    {
      name: "المتوسط",
      data: topTenAvgDeg.length ? topTenAvgDeg : [],
    },
    {
      name: "الاختبار الاول",
      data: topTenFirstDeg.length ? topTenFirstDeg : [],
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: apaexlineColumnColors,
    xaxis: {
      categories: topTenName.length ? topTenName : [],
    },
    yaxis: {
      title: {
        text: "الدرجات",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val + " درجة"
        },
      },
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  )
}

export default Apaexlinecolumn
