import React, { useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { set } from "lodash"

const SocialSource = ({schoolNewData}) => {

  const [sortedScool, setSortedSchool] = React.useState([])


  useEffect(() => {
    if(schoolNewData.length) {
      console.log("schoolNewData", schoolNewData)
      // const maxDegItem = schoolNewData.reduce((max, current) => (current.degreeAvg > max.degreeAvg ? current : max), schoolNewData[0]);
      const sortedScool = [...schoolNewData].sort((a, b) => b.totalDegreePerSchool - a.totalDegreePerSchool);
      console.log("sortedScool", sortedScool)
      setSortedSchool(sortedScool)
    }
  }, [schoolNewData])
  
  const socials = [
    {
      title: sortedScool.length && sortedScool[3].schoolName,
      bgColor: "bg-primary",
      // iconClass: "mdi-facebook",
      description: `${sortedScool.length && sortedScool[3].stdsDetails.length} طالب`,
      count: 4
    },
    {
      title: sortedScool.length && sortedScool[2].schoolName,
      bgColor: "bg-primary",
      // iconClass: "mdi-facebook",
      description: `${sortedScool.length && sortedScool[2].stdsDetails.length} طالب`,
      count: 3
    },
    {
      title: sortedScool.length && sortedScool[1].schoolName,
      bgColor: "bg-primary",
      // iconClass: "mdi-facebook",
      description: `${sortedScool.length && sortedScool[1].stdsDetails.length} طالب`,
      count: 2
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-end">اعلي ٤ مدارس تحصيلا للدرجات</CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24"> 
                <span className="text-black">١</span>
                {/* <i className="mdi mdi-facebook text-primary"></i> */}
              </span>
            </div>
            <p className="font-16 text-muted mb-2"></p>
            <h5>
              <Link to="#" className="text-dark">
                {sortedScool.length && sortedScool[0].schoolName} - <span className="text-muted font-16">{sortedScool.length && sortedScool[0].stdsDetails.length} طالب</span>{" "}
              </Link>
            </h5>
            <p className="text-muted">
              المدرسة الأعلي تحصلا للدرجات
            </p>
            {/* <Link to="#" className="text-primary font-16">
              Learn more <i className="mdi mdi-chevron-right"></i>
            </Link> */}
          </div>
          <Row className="mt-4">
            {socials.map((social, key) => (
              <Col className="col-4" key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">

                    <span
                      className={
                        "avatar-title rounded-circle " +
                        social.bgColor +
                        " font-size-16"
                      }
                    >
                      <i
                        className={"mdi " +  " text-white"}
                      ></i>{social.count}
                    </span>
                  </div>
                  <h5 className="font-size-15">{social.title}</h5>
                  <p className="text-muted mb-0">{social.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
