import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"

const ActivityComp = () => {
  return (
    <React.Fragment>
      <Card>
        <CardBody dir="rtl">
          <CardTitle className="mb-5">النشاط</CardTitle>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bxs-left-arrow-circle font-size-18 text-primary bx-fade-left" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="">
                  <h5 className="font-size-14 d-flex">
                    <i className="bx bx-left-arrow-alt font-size-16 text-primary align-middle ms-2 d-block" />
                    <span className="block">١٢نوفمبر</span>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div className="px-1">
                    تفوق الطالب أحمد علي في جميع المسابقات.
                  </div>
                </div>
              </div>
            </li>

            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-left-arrow-circle font-size-18" />
              </div>
              <div className="d-flex">
                <div className="">
                  <h5 className="font-size-14 d-flex">
                    <i className="bx bx-left-arrow-alt font-size-16 text-primary align-middle ms-2 d-block" />
                    <span className="block">١٢نوفمبر</span>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div id="activitytext" className="px-1">مستوى ضعيف في مسابقة العلوم</div>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-left-arrow-circle font-size-18" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="">
                  <h5 className="font-size-14 d-flex">
                    <i className="bx bx-left-arrow-alt font-size-16 text-primary align-middle ms-2 d-block" />
                    <span className="block">١٢نوفمبر</span>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div className="px-1">منافسة قوية في مسابقة الكيمياء</div>
                </div>
              </div>
            </li>
            <li className="event-list active">
              <div className="event-timeline-dot">
                <i className="bx bxs-left-arrow-circle font-size-18" />
              </div>
              <div className="flex-shrink-0 d-flex">
                <div className="">
                  <h5 className="font-size-14 d-flex">
                    <i className="bx bx-left-arrow-alt font-size-16 text-primary align-middle ms-2 d-block" />
                    <span className="block">١٢نوفمبر</span>
                  </h5>
                </div>
                <div className="flex-grow-1">
                  <div className="px-1">
                    مدرسة الأزهر تتفوق في مسابقة الرياضيات
                  </div>
                </div>
              </div>
            </li>
          </ul>
          {/* <div className="text-center mt-4">
            <Link
              to="#"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              View More <i className="mdi mdi-arrow-right ms-1" />
            </Link>
          </div> */}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ActivityComp
