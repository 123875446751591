import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

const StudentsTotalCard = ({ schoolNewData }) => {
  const [girlsCount, setGirlsCount] = useState(0)
  const [boysCount, setBoysCount] = useState(0)

  useEffect(() => {
    if (schoolNewData.length > 0) {
      let boys = 0
      let girls = 0
  
      schoolNewData.map(school => {
        if (school.gender === "بنين") {
          boys = boys + school.stdsDetails.length
        }
        if (school.gender === "بنات") {
          girls = girls + school.stdsDetails.length
        }
      })
      setGirlsCount(girls)
      setBoysCount(boys)
    }
  }, [schoolNewData])

  const reports = [
    {
      id: "total",
      title: "عدد الطلاب الكلي",
      iconClass: "bx-copy-alt",
      description: boysCount + girlsCount,
    },
    {
      id: "boy",
      title: "الطلاب البنين",
      iconClass: "bx-archive-in",
      description: boysCount,
    },
    {
      id: "girl",
      title: "الطالبات البنات",
      iconClass: "bx-purchase-tag-alt",
      description: girlsCount,
    },
  ]
  return (
    <Row>
      {/* Reports Render */}
      {reports.map((report, key) => (
        <Col md="4" key={"_col_" + key}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">{report.title}</p>
                  <h4 className="mb-0">{report.description}</h4>
                </div>
                <div
                  className={`avatar-sm rounded-circle align-self-center mini-stat-icon`}
                >
                  <span
                    className={`avatar-title rounded-circle ${
                      report.id === "total"
                        ? "bg_gray"
                        : report.id === "boy"
                        ? "bg-primary"
                        : "bg-pink"
                    }`}
                  >
                    <i
                      className={"bx " + report.iconClass + " font-size-24"}
                    ></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default StudentsTotalCard
