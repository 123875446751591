import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopCities = ({ schoolNewData }) => {
  const [sortedScool, setSortedSchool] = useState([])
  const [firstSchoolStdSorted, setFirstStdSchoolSorted] = useState([])

  useEffect(() => {
    if (schoolNewData.length) {
      // const maxDegItem = schoolNewData.reduce((max, current) => (current.degreeAvg > max.degreeAvg ? current : max), schoolNewData[0]);
      const sortedScool = [...schoolNewData].sort(
        (a, b) => b.totalDegreePerSchool - a.totalDegreePerSchool
      )
      setSortedSchool(sortedScool)
    }
  }, [schoolNewData])

  useEffect(() => {
    if (sortedScool.length) {
      const sortedShcool = [...sortedScool[0].stdsDetails].sort(
        (a, b) => b.avg - a.avg
      )
      setFirstStdSchoolSorted(sortedShcool)
    }
  }, [sortedScool])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-end">
            اعلى 3 طلاب من المدرسه الاعلى تحصيلا للدرجات
          </CardTitle>
          <div className="text-center">
            <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                <span className="text-black">١</span>
                {/* <i className="mdi mdi-facebook text-primary"></i> */}
              </span>
            </div>
            <p>{sortedScool.length && sortedScool[0].schoolName}</p>
            <h3>
              {" "}
              طالب {sortedScool.length && sortedScool[0].stdsDetails.length}
            </h3>
          </div>

          <div className="table-responsive mt-4" dir="rtl">
            <table className="table align-middle table-nowrap">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">
                      {firstSchoolStdSorted.length &&
                        firstSchoolStdSorted[0].stdName}
                    </p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h5 className="mb-0">
                      {firstSchoolStdSorted.length &&
                        firstSchoolStdSorted[0].degreeTrm1 +
                          firstSchoolStdSorted[0].degreeTrm2}
                    </h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="primary"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">
                      {firstSchoolStdSorted.length &&
                        firstSchoolStdSorted[1].stdName}
                    </p>
                  </td>
                  <td>
                    <h5 className="mb-0">
                      {firstSchoolStdSorted.length &&
                        firstSchoolStdSorted[1].degreeTrm1 +
                          firstSchoolStdSorted[1].degreeTrm2}
                    </h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="success"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="mb-0">
                      {firstSchoolStdSorted.length>2 &&
                        firstSchoolStdSorted[2].stdName}
                    </p>
                  </td>
                  <td>
                    <h5 className="mb-0">
                      {firstSchoolStdSorted.length>2 &&
                        firstSchoolStdSorted[2].degreeTrm1 +
                          firstSchoolStdSorted[2].degreeTrm2}
                    </h5>
                  </td>
                  <td>
                    <Progress
                      value="100"
                      color="warning"
                      className="bg-transparent progress-sm"
                      size="sm"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopCities
