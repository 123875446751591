import img1 from "../../assets/images/certificate/cert1.jpg"

const yearData = [
  {
    name: "Series A",
    data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 21, 14, 11, 18, 17, 12, 20, 18],
  },
]

const monthData = [
  {
    name: "Series A",
    data: [34, 55, 21, 77, 32, 63, 86, 42, 34, 18, 16, 41],
  },
  {
    name: "Series B",
    data: [10, 63, 40, 80, 52, 41, 11, 32, 30, 86, 44, 33],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 85, 21, 14, 80, 58, 17, 12, 20, 18],
  },
]

const weekData = [
  {
    name: "Series A",
    data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
  },
  {
    name: "Series B",
    data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
  },
  {
    name: "Series C",
    data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
  },
]

const latestTransaction = [
  {
    orderId: "#SK2545",
    billingName: "Jacob Hunter",
    orderdate: "04 Oct, 2019",
    total: "$392",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2544",
    billingName: "Ronald Taylor",
    orderdate: "04 Oct, 2019",
    total: "$404",
    paymentStatus: "Refund",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2543",
    billingName: "Barry Dick",
    orderdate: "05 Oct, 2019",
    total: "$412",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
  {
    orderId: "#SK2542",
    billingName: "Juan Mitchell",
    orderdate: "06 Oct, 2019",
    total: "$384",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-paypal",
    paymentMethod: "Paypal",
  },
  {
    orderId: "#SK2541",
    billingName: "Jamal Burnett",
    orderdate: "07 Oct, 2019",
    total: "$380",
    paymentStatus: "Chargeback",
    methodIcon: "fab fa-cc-visa",
    paymentMethod: "Visa",
  },
  {
    orderId: "#SK2540",
    billingName: "Neal Matthews",
    orderdate: "07 Oct, 2019",
    total: "$400",
    paymentStatus: "Paid",
    methodIcon: "fab fa-cc-mastercard",
    paymentMethod: "Mastercard",
  },
]

const schoolData = [
  {
    id: 1,
    schoolName: "قطر التقنية للبنين ",
    learningType: "تقني",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " فتحي",
        degreeTrm1: 4,
        degreeTrm2: 7,
      },
      {
        id: 2,
        stdName: " سعد خالد السليطى",
        degreeTrm1: 12,
        degreeTrm2: 9,
      },

      {
        id: 3,
        stdName: " فلاح على صالح الغضيض المري",
        degreeTrm1: 6,
        degreeTrm2: "-",
      },
      {
        id: 4,
        stdName: " سعيد حبيب",
        degreeTrm1: 10,
        degreeTrm2: "-",
      },
      {
        id: 5,
        stdName: " محمد",
        degreeTrm1: "-",
        degreeTrm2: 28,
      },

      {
        id: 6,
        stdName: " ةة ",
        degreeTrm1: "-",
        degreeTrm2: 14,
      },
      {
        id: 7,
        stdName: " عبدالله على العبيدلى ",
        degreeTrm1: "-",
        degreeTrm2: 14,
      },
      {
        id: 8,
        stdName: " ابراهيم سطام المريخي ",
        degreeTrm1: "-",
        degreeTrm2: 12,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 2,
    schoolName: "قطر التقنية للبنات",
    learningType: "تقني",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      // {
      //   id: 1,
      //   stdName: "محمد",
      //   degreeTrm1: 99,
      //   degreeTrm2: 75,
      //   avg: 89,
      //   certificate: "cert1.jpg",
      // },
      {
        id: 1,
        stdName: " حصة ناصرعلى الهاجري ",
        degreeTrm1: 12,
        degreeTrm2: 7,
      },
      {
        id: 2,
        stdName: " ساره خالد السعيد ",
        degreeTrm1: 14,
        degreeTrm2: 21,
      },
      {
        id: 3,
        stdName: " صباح هشام عاشور ",
        degreeTrm1: 8,
        degreeTrm2: 8,
      },
      {
        id: 4,
        stdName: " عائشة سعيد محمد المري ",
        degreeTrm1: 20,
        degreeTrm2: 14,
      },
      {
        id: 5,
        stdName: " شهد عادل عبدالله ",
        degreeTrm1: 16,
        degreeTrm2: 8,
      },
      {
        id: 6,
        stdName: " تبيان محمد أحمد ",
        degreeTrm1: 6,
        degreeTrm2: 12,
      },
      {
        id: 7,
        stdName: " مريم شكيل احمد ",
        degreeTrm1: 8,
        degreeTrm2: 16,
      },
      {
        id: 8,
        stdName: " اميمه احمد على السلفى ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 9,
        stdName: " تسنيم ديرفس ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 10,
        stdName: " اسماء خليل ابوندا ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 11,
        stdName: " ريان عامر صديق محمد ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 12,
        stdName: " فجر خالد حمزه ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 13,
        stdName: " سجود فتحى ابراهيم ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 14,
        stdName: " ابرار حسن احمد الهدع محمد ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 3,
    schoolName: "ابن تيميه",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      // {
      //   id: 1,
      //   stdName: "محمد",
      //   degreeTrm1: 99,
      //   degreeTrm2: 75,
      //   avg: 89,
      //   certificate: img1,
      // },
      {
        id: 1,
        stdName: " محمد وليد محمد اليافعى ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " احمد اعجاز احمد ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " صهيب جبيرعبد دهمان ",
        degreeTrm1: 24,
        degreeTrm2: 30,
      },
      {
        id: 4,
        stdName: " المعتصم بالله بشار ضاهر ",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 5,
        stdName: " عبدالله يوسف دلول ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " سليمان محمود على بور ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 7,
        stdName: " جهاد ايمن عبدالرحيم ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 8,
        stdName: " حسن هاجس صالح ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 9,
        stdName: " فيصل عيضة محمد واصل الوائلى ",
        degreeTrm1: "-",
        degreeTrm2: 25,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 4,
    schoolName: "اروى بنت عبد المطلب",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      // {
      //   id: 1,
      //   stdName: "محمد",
      //   degreeTrm1: 99,
      //   degreeTrm2: 75,
      //   avg: 89,
      //   certificate: img1,
      // },
      {
        id: 1,
        stdName: " سميه محمد عبدالوهاب ",
        degreeTrm1: 28,
        degreeTrm2: "-",
      },
      {
        id: 2,
        stdName: " يسرا نادي محمد ",
        degreeTrm1: 22,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " بيسان محمد يونس ",
        degreeTrm1: 22,
        degreeTrm2: 22,
      },
      {
        id: 4,
        stdName: " اسلام حسن محمد ",
        degreeTrm1: 18,
        degreeTrm2: 10,
      },
      {
        id: 5,
        stdName: " ملينا ابراهيم حساين ",
        degreeTrm1: 20,
        degreeTrm2: "-",
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 5,
    schoolName: "الايمان",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      // {
      //   id: 1,
      //   stdName: "محمد",
      //   degreeTrm1: 99,
      //   degreeTrm2: 75,
      //   avg: 89,
      //   certificate: img1,
      // },
      {
        id: 1,
        stdName: " جنى محمد عكور ",
        degreeTrm1: 26,
        degreeTrm2: 24,
      },
      {
        id: 2,
        stdName: " اسراء خالد حمزة جادالرب على ",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: " بسمة محمد عبدالمنعم محمد السيد ",
        degreeTrm1: 24,
        degreeTrm2: 24,
      },
      {
        id: 4,
        stdName: " نور غدير محمد صالح العيسوي ",
        degreeTrm1: 22,
        degreeTrm2: 24,
      },
      {
        id: 5,
        stdName: " فجر عبدالعزيز العبدالله ",
        degreeTrm1: 24,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " ملك السيد عبدالحميد على العبد ",
        degreeTrm1: 22,
        degreeTrm2: 24,
      },
      {
        id: 7,
        stdName: " دانه عبدالرحمن الملا ",
        degreeTrm1: 14,
        degreeTrm2: 24,
      },
      {
        id: 8,
        stdName: " كنزي اسلام حامد ابراهيم منيسى ",
        degreeTrm1: 18,
        degreeTrm2: "-",
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 6,
    schoolName: "البيان",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      // {
      //   id: 1,
      //   stdName: "محمد",
      //   degreeTrm1: 99,
      //   degreeTrm2: 75,
      //   avg: 89,
      //   certificate: img1,
      // },
      {
        id: 1,
        stdName: " بنه احمد الجابر ",
        degreeTrm1: 10,
        degreeTrm2: "-",
      },
      {
        id: 2,
        stdName: " حصه خلف فارس البنعلي المهندي ",
        degreeTrm1: 10,
        degreeTrm2: 9,
      },
      {
        id: 3,
        stdName: " سلمى محمد المسند المهندي ",
        degreeTrm1: 8,
        degreeTrm2: 5,
      },
      {
        id: 4,
        stdName: " لولان ابراهيم السعدي ",
        degreeTrm1: 24,
        degreeTrm2: 22,
      },
      {
        id: 5,
        stdName: " المياسة خالد المسلم ",
        degreeTrm1: 16,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " حصه خلف فارس جاسم البنعلى المهندى ",
        degreeTrm1: 28,
        degreeTrm2: "-",
      },
      {
        id: 7,
        stdName: " منة الله مصطفى ابراهيم جبريل ",
        degreeTrm1: 22,
        degreeTrm2: 11,
      },
      {
        id: 8,
        stdName: " الجوري محمد القحطانى ",
        degreeTrm1: 24,
        degreeTrm2: "-",
      },
      {
        id: 9,
        stdName: "pwi",
        degreeTrm1: "-",
        degreeTrm2: 12,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },

  {
    id: 7,
    schoolName: "الجميليه",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " تميم محمد توفيق الحجات ",
        degreeTrm1: 22,
        degreeTrm2: 14,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },

  {
    id: 8,
    schoolName: "الجميليه",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " احمد اموري محمد على ",
        degreeTrm1: 24,
        degreeTrm2: 25,
      },
      {
        id: 2,
        stdName: " حازم احمد الحوراني ",
        degreeTrm1: 20,
        degreeTrm2: 22,
      },
      {
        id: 3,
        stdName: " انس محمد عبدالبديع ",
        degreeTrm1: 26,
        degreeTrm2: 19,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 9,
    schoolName: "الخرسعه",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " تقى الدين محمد محمود ",
        degreeTrm1: 28,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " عبدالرحمن خليل حسن ",
        degreeTrm1: 28,
        degreeTrm2: 28,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 10,
    schoolName: "الخيار 44",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " halland<cold palmer ",
        degreeTrm1: 22,
        degreeTrm2: "-",
      },

      {
        id: 2,
        stdName: " ز  ",
        degreeTrm1: "-",
        degreeTrm2: 28,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },

  {
    id: 11,
    schoolName: "الدخان",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " جواهر محمد الزهر ",
        degreeTrm1: 12,
        degreeTrm2: 23,
      },
      {
        id: 2,
        stdName: " وداد عبيد الدبس ",
        degreeTrm1: 16,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " تسنيم احمد على ",
        degreeTrm1: 10,
        degreeTrm2: 8,
      },
      {
        id: 4,
        stdName: " مريم ميلاد ",
        degreeTrm1: 26,
        degreeTrm2: 28,
      },
      {
        id: 5,
        stdName: " أميمة أحمد عبدالغنى ",
        degreeTrm1: 28,
        degreeTrm2: 25,
      },
      {
        id: 6,
        stdName: " هاجر نادي فكري ابراهيم عبدالعزيز ",
        degreeTrm1: 28,
        degreeTrm2: "-",
      },
      {
        id: 7,
        stdName: " الدانه سلطان البلوشى ",
        degreeTrm1: 30,
        degreeTrm2: 5,
      },
      {
        id: 8,
        stdName: " جواهر عسكر ",
        degreeTrm1: "-",
        degreeTrm2: 5,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 12,
    schoolName: "الدوحة",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " محمد فهد الدوسري ",
        degreeTrm1: 24,
        degreeTrm2: "-",
      },
      {
        id: 2,
        stdName: " مصطفى تامر عبدالمعطي مبروك ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " mohammed jamil said abukhadra ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 4,
        stdName: " معاذ محمد عبده ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 5,
        stdName: " Albaraa Asim Alhattami ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " محمود على عامر ",
        degreeTrm1: "-",
        degreeTrm2: 18,
      },
      {
        id: 7,
        stdName: " خليل ابراهيم هندي ",
        degreeTrm1: "-",
        degreeTrm2: 21,
      },
      {
        id: 8,
        stdName: " زياد محمد ابراهيم أنس ابوجعفر ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 9,
        stdName: " مصطفى ايمن حديدي ",
        degreeTrm1: "-",
        degreeTrm2: 23,
      },
      {
        id: 10,
        stdName: " محمد السيد عبدالعزيز ",
        degreeTrm1: "-",
        degreeTrm2: 23,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },

  {
    id: 13,
    schoolName: "الرسالة",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " لانا بكري كيتوع ",
        degreeTrm1: "-",
        degreeTrm2: 15,
      },
      {
        id: 2,
        stdName: " ساره هاشم ",
        degreeTrm1: "-",
        degreeTrm2: 20,
      },
      {
        id: 3,
        stdName: " وجدان محمد القريوتي ",
        degreeTrm1: "-",
        degreeTrm2: 22,
      },
      {
        id: 4,
        stdName: " مريم ابراهيم مصطفى اسماعيل ",
        degreeTrm1: "-",
        degreeTrm2: 24,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 14,
    schoolName: "الريان الخاصة",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " مريم محمد السيد ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " تالة فراس بوبس ",
        degreeTrm1: 28,
        degreeTrm2: 23,
      },
      {
        id: 3,
        stdName: " فرح معاوية عودة ",
        degreeTrm1: 28,
        degreeTrm2: 23,
      },
      {
        id: 4,
        stdName: " جولي ايمن نقاشيه ",
        degreeTrm1: 30,
        degreeTrm2: 23,
      },
      {
        id: 5,
        stdName: " تالا كعوش ",
        degreeTrm1: 30,
        degreeTrm2: 23,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 15,
    schoolName: "السيلية",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " مريم ",
        degreeTrm1: 4,
        degreeTrm2: "-",
      },
      {
        id: 2,
        stdName: " كنزي وليد محمد ",
        degreeTrm1: 12,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " ماب اسلام محجوب ",
        degreeTrm1: 16,
        degreeTrm2: 10,
      },
      {
        id: 4,
        stdName: " شهد حمد محمد الغفراني ",
        degreeTrm1: 26,
        degreeTrm2: "-",
      },
      {
        id: 5,
        stdName: " مريم حمد فهيد عويضه المري ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " جنى يمان حسناوي ",
        degreeTrm1: 30,
        degreeTrm2: 12,
      },
      {
        id: 7,
        stdName: " سلوى شعيب غلام صديق ",
        degreeTrm1: 30,
        degreeTrm2: 12,
      },
      {
        id: 8,
        stdName: " ضباء شفيع راشد ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 9,
        stdName: " رناد مهند العدوي ",
        degreeTrm1: 30,
        degreeTrm2: 10,
      },
      {
        id: 10,
        stdName: " عبير نورالدين محمود ",
        degreeTrm1: 18,
        degreeTrm2: "-",
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 16,
    schoolName: "الشمال للبنات",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " مريم حسين السادة ",
        degreeTrm1: 12,
        degreeTrm2: 10,
      },
      {
        id: 2,
        stdName: " ضحى محمد عبدالنعيم ",
        degreeTrm1: 30,
        degreeTrm2: 16,
      },
      {
        id: 3,
        stdName: " مريم شاهر محمد فوزي ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 4,
        stdName: " نور محمد احمد حسن ",
        degreeTrm1: 30,
        degreeTrm2: 15,
      },
      {
        id: 5,
        stdName: " رقية حسين هلال ",
        degreeTrm1: 30,
        degreeTrm2: 11,
      },
      {
        id: 6,
        stdName: " نانسى محمد محمود ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 7,
        stdName: " مريم المهند اسماعيل عارف سمور ",
        degreeTrm1: 30,
        degreeTrm2: 19,
      },
      {
        id: 8,
        stdName: " حصه خالد المهيزع ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 9,
        stdName: " موزه ناصر الفضاله ",
        degreeTrm1: "-",
        degreeTrm2: 15,
      },
      {
        id: 10,
        stdName: " اروى عطيه محمد ",
        degreeTrm1: "-",
        degreeTrm2: 18,
      },
      {
        id: 11,
        stdName: " سندس ياسر عبدالغنى الشوا ",
        degreeTrm1: "-",
        degreeTrm2: 19,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 17,
    schoolName: "الشيماء",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " اميمه احمد علي احمد السلفى ",
        degreeTrm1: 28,
        degreeTrm2: "-",
      },
      {
        id: 2,
        stdName: " سجود فتحي ابراهيم صالح ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 3,
        stdName: " اسماء خليل حسني محمد ابوندا ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 4,
        stdName: " فجر خالد ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 5,
        stdName: " دارين ابراهيم محمد سعفان ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " ابرار حسن احمد الهدع محمد ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
      {
        id: 7,
        stdName: " ريان عامر صديق محمد ",
        degreeTrm1: 30,
        degreeTrm2: "-",
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        gender: "male",
      },
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        gender: "male",
      },
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        gender: "male",
      },
    ],
  },
  {
    id: 18,
    schoolName: "العب",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " عسراء بوحوش لطفي ",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 2,
        stdName: " جنى زياد على ",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 2,
        stdName: " مها ابراهيم محمد حسن النجار ",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 3,
        stdName: " لبابة محمد قناعة ",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 4,
        stdName: " روان احمد ابوشاهين ",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 19,
    schoolName: "الكعبان الثانوية",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " معاذ عبدالمنعم السيد عبدالمنعم ",
        degreeTrm1: 28,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " محمد حسن عبيد حسن ",
        degreeTrm1: 26,
        degreeTrm2: 27,
      },
      {
        id: 3,
        stdName: " على محمد زكريا ",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: " احمد صلاح محمد ",
        degreeTrm1: 20,
        degreeTrm2: 25,
      },
      {
        id: 5,
        stdName: " محمد عثمان محمد ساتي ",
        degreeTrm1: 24,
        degreeTrm2: 30,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 20,
    schoolName: "الكعبان المشتركة",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " بسنت احمد حسني مصطفى حسن ",
        degreeTrm1: 16,
        degreeTrm2: 14,
      },
      {
        id: 2,
        stdName: " عايشة مسهوج الخلف ",
        degreeTrm1: 16,
        degreeTrm2: 15,
      },
      {
        id: 3,
        stdName: " بدور الثلجي ",
        degreeTrm1: 14,
        degreeTrm2: 15,
      },
      {
        id: 4,
        stdName: " فاطمة امين العبد ",
        degreeTrm1: 12,
        degreeTrm2: "-",
      },
      {
        id: 5,
        stdName: " نور الهدى يحيى",
        degreeTrm1: 10,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " سهيلة أحمد محمد شبل ",
        degreeTrm1: 22,
        degreeTrm2: 20,
      },
      {
        id: 7,
        stdName: "رغد موسى الحاج حسين ",
        degreeTrm1: 20,
        degreeTrm2: 16,
      },
      {
        id: 8,
        stdName: " الزهراء محمد فايد ",
        degreeTrm1: 24,
        degreeTrm2: 23,
      },
      {
        id: 9,
        stdName: " فرح هاني ",
        degreeTrm1: 22,
        degreeTrm2: 17,
      },
      {
        id: 10,
        stdName: " بسملة ايمن ",
        degreeTrm1: 18,
        degreeTrm2: "-",
      },
      {
        id: 11,
        stdName: " رغد خالد الفيحاني ",
        degreeTrm1: 10,
        degreeTrm2: 10,
      },
      {
        id: 12,
        stdName: " norhan ayman ",
        degreeTrm1: 18,
        degreeTrm2: "-",
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 21,
    schoolName: "الكوثر",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " فاطمة ابراهيم عاطف ابراهيم ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " ميرال معتز محمد ",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 3,
        stdName: " فاطمة الزهراء عبدالفتاح خليل عبدالفتاح زغلول ",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: " أسماء باسل العبد الرزاق ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 5,
        stdName: " مريم اسامه صلاح ",
        degreeTrm1: 28,
        degreeTrm2: 30,
      },
      {
        id: 6,
        stdName: " منى محمد عبدالسلام ",
        degreeTrm1: 28,
        degreeTrm2: 25,
      },
      {
        id: 7,
        stdName: " جويرية محمد ابراهيم هاشم ابراهيم ",
        degreeTrm1: 28,
        degreeTrm2: 28,
      },
      {
        id: 8,
        stdName: " حبيبه السيد محمد عبدالفتاح ",
        degreeTrm1: 30,
        degreeTrm2: 23,
      },
      {
        id: 9,
        stdName: " جوري محمد ",
        degreeTrm1: "-",
        degreeTrm2: 9,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 22,
    schoolName: "المجتمع الخاصه",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " عاليه حازم الحسون ",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 2,
        stdName: " هنا محمد محفوظ حسانين ",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: " هنا علم محمد ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 4,
        stdName: " ساره حسين سليمان برهومه ",
        degreeTrm1: 26,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: " مريم محمد احمد ابراهيم جبر ",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 6,
        stdName: " نون حسن على حسن ",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 7,
        stdName: " مريم عبدالعزيز عبدالعزيز محمد ",
        degreeTrm1: 24,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 23,
    schoolName: "الوكرة الثانويه",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " احمد حسين يوسف ناصر ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 2,
        stdName: " احمد الامين جباره الله التوم ",
        degreeTrm1: 26,
        degreeTrm2: 30,
      },
      {
        id: 3,
        stdName: " احمد حسين السيد عبدالله العيدروس ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 4,
        stdName: " يوسف يعقوب المنصوري ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 5,
        stdName: " اسماعيل محمد اسماعيل ",
        degreeTrm1: 20,
        degreeTrm2: "-",
      },
      {
        id: 6,
        stdName: " زكريا احمد يوسف الجناحي ",
        degreeTrm1: 28,
        degreeTrm2: 30,
      },
      {
        id: 7,
        stdName: " ابراهيم سلطان المناعي ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 8,
        stdName: " عبدالمحسن علي ابراهيم البحيح ",
        degreeTrm1: 26,
        degreeTrm2: 30,
      },
      {
        id: 9,
        stdName: " محمد احمد محمد العمادي ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 10,
        stdName: " حمزه محمد سالمان ",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 11,
        stdName: " احمد طارق احمد السيد نصار ",
        degreeTrm1: 24,
        degreeTrm2: 15,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 24,
    schoolName: "الوكرة الثانويه",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " جوري محمد شختور",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: " فوزية مصطفى ",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: " الاء محمد شامخ محمد ابوقمر",
        degreeTrm1: 24,
        degreeTrm2: 23,
      },
      {
        id: 4,
        stdName: " ندى محمود سليم محمد مطر",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: " لجين علي عقرباوي ",
        degreeTrm1: 28,
        degreeTrm2: 23,
      },
      {
        id: 6,
        stdName: " سدين صالح وقاد ",
        degreeTrm1: 30,
        degreeTrm2: 23,
      },
      {
        id: 7,
        stdName: "حلا احمد السيد",
        degreeTrm1: 30,
        degreeTrm2: 26,
      },
      {
        id: 8,
        stdName: " اسيا عبدالفتاح لحاج عبدالمعطي",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 9,
        stdName: "الاء خالد محمد",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 10,
        stdName: "ساره محمد جبر محمد البوعينين",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 11,
        stdName: "حلا اسامه الخطيب",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 25,
    schoolName: "الوكير الثانويه",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " منة الله عماد",
        degreeTrm1: 30,
        degreeTrm2: 14,
      },
      {
        id: 2,
        stdName: " ايمان علاء الدين محمد عبد الصمد ابو عامر ",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: " منة الله ثروت مصطفي",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 4,
        stdName: " رحمة محمد فاروق عبد الفتاح",
        degreeTrm1: 30,
        degreeTrm2: 17,
      },
      {
        id: 5,
        stdName: " حبيبة مصطفى حافظ عمار",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 26,
    schoolName: "ام ايمن",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " رغد سعيد سنوسي خليفة ",
        degreeTrm1: 22,
        degreeTrm2: 22,
      },
      {
        id: 2,
        stdName: " يارا محمد سيد حسن سليم ",
        degreeTrm1: 22,
        degreeTrm2: 7,
      },

      {
        id: 3,
        stdName: "شروق النميري ابراهيم",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: " خديجة عبدالله محمد ",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: " وعد مهند",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 27,
    schoolName: "ام الحكيم",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " مرام اسامة الزاهد ",
        degreeTrm1: 24,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: " نور سامر سالم ",
        degreeTrm1: 22,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: " مريم عصام محمد نعمان قرش",
        degreeTrm1: 24,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: "اسماء محمد محي الدين",
        degreeTrm1: 24,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: " سلمى صلاح خلف",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 28,
    schoolName: "امنه بنت وهب",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "يارا فراس العيد",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 2,
        stdName: " مريم محمد عمرون عبد الباقي ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 3,
        stdName: " داليه محمد صبري المسعود",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: " شهد القداح",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: "ريم محمد خلف كراسنه",
        degreeTrm1: 24,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: " مرام عطيه شلبى ",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 29,
    schoolName: "ايبلا للبنات الخاصة",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " فرح يزبك",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 2,
        stdName: " شهد الدرويش",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 3,
        stdName: " جود ماجد العابد",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: " سارة اسامة كنينة",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: " هبة محمد العكاري",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 30,
    schoolName: "ايبلا للبنين الخاصة",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " محمد باسل بريشو ",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 2,
        stdName: " نواف طه علي سلام ",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 3,
        stdName: " عبدالرشيد العبود ",
        degreeTrm1: 28,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: "علي بربهان",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: "مارك ضاهر",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 31,
    schoolName: "جاسم بن حمد",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "سيد عبدالله سيد إبراهيم",
        degreeTrm1: 26,
        degreeTrm2: 27,
      },
      {
        id: 2,
        stdName: "احمد الحسيني علي الخولي",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 3,
        stdName: "يحيى محمد سيد",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: " عادل محمد عادل مطالقة",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: "عبدالله خالد شديد",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 6,
        stdName: "سيف خالد حمدي",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 32,
    schoolName: "حسان بن ثابت",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "جاد علي المنصري",
        degreeTrm1: 22,
        degreeTrm2: 22,
      },
      {
        id: 2,
        stdName: "محمد امين بن كمال صعدولي",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 3,
        stdName: "محمد متولي فتوح متولي حمودة",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 4,
        stdName: "مؤتمن محمد تالناجي",
        degreeTrm1: 0,
        degreeTrm2: 15,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 33,
    schoolName: "حمد بن عبد الله بن جاسم",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "محمد مراد علي أبوبكر",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: "تميم سالم خلف",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 34,
    schoolName: "رابعة العدوية",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "لين عمر أبولبن",
        degreeTrm1: 18,
        degreeTrm2: 25,
      },
      {
        id: 2,
        stdName: "يارا محمد الياس",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: "حنين فراس عبدالعليم الحصني",
        degreeTrm1: 30,
        degreeTrm2: 14,
      },
      {
        id: 4,
        stdName: "حلا عبدالرحمن زوبع",
        degreeTrm1: 24,
        degreeTrm2: 14,
      },
      {
        id: 5,
        stdName: "تسنيم عادل بن محجوب",
        degreeTrm1: 30,
        degreeTrm2: 30,
      },
      {
        id: 6,
        stdName: "سوزان وليد كمال محمد سعد",
        degreeTrm1: 28,
        degreeTrm2: 12,
      },
      {
        id: 7,
        stdName: "نور الله عاصم محمد",
        degreeTrm1: 30,
        degreeTrm2: 16,
      },
      {
        id: 8,
        stdName: "ندى احمد",
        degreeTrm1: 30,
        degreeTrm2: 8,
      },
      {
        id: 9,
        stdName: "زهرة ياسر محمود سلطان",
        degreeTrm1: 30,
        degreeTrm2: 16,
      },
      {
        id: 10,
        stdName: "ريم رمضان مسعد",
        degreeTrm1: 26,
        degreeTrm2: 23,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 35,
    schoolName: "رملة بنت ابي سفيان",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "يقين احمد حسن",
        degreeTrm1: 20,
        degreeTrm2: 15,
      },
      {
        id: 2,
        stdName: "مريم وسيم",
        degreeTrm1: 20,
        degreeTrm2: 15,
      },
      {
        id: 3,
        stdName: "ايه سعدون",
        degreeTrm1: 22,
        degreeTrm2: 15,
      },
      {
        id: 4,
        stdName: " ساره علي محمد ناصر ",
        degreeTrm1: 30,
        degreeTrm2: 19,
      },
      {
        id: 5,
        stdName: "ايثار مسعد ابراهيم",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: "روضه احمد محمد صالح",
        degreeTrm1: 30,
        degreeTrm2: 19,
      },
      {
        id: 7,
        stdName: "سارة محمد علي محمد شحاته",
        degreeTrm1: 28,
        degreeTrm2: 15,
      },
      {
        id: 8,
        stdName: "يقين ابراهيم جوهر النعيمي ",
        degreeTrm1: 28,
        degreeTrm2: 20,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 36,
    schoolName: "روضة بنت جاسم",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: " جنى عبدالرحمن ",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: " عاليه صلاح عبدربه اليافعي ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 3,
        stdName: " جنى اسامة ",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: " ليان احمد السالمي ",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: " حلا جهاد محمد سعد ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 6,
        stdName: " جواهر علي احمد العجمي ",
        degreeTrm1: 30,
        degreeTrm2: 22,
      },
      {
        id: 7,
        stdName: "جنى محفوظ دلول",
        degreeTrm1: 30,
        degreeTrm2: 19,
      },
      {
        id: 8,
        stdName: "m",
        degreeTrm1: 0,
        degreeTrm2: 2,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 37,
    schoolName: "روضة بنت محمد",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "سارة سنان الحجاجي",
        degreeTrm1: 22,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: "الجازي عبدالله الامير",
        degreeTrm1: 30,
        degreeTrm2: 8,
      },
      {
        id: 3,
        stdName: "سارة محمد الربيع",
        degreeTrm1: 12,
        degreeTrm2: 21,
      },
      {
        id: 4,
        stdName: "رهف اسلام",
        degreeTrm1: 16,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: "العنود سحيم",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: "جمان خضر اسكندر سعادة",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 7,
        stdName: "حلا باسم الفارس",
        degreeTrm1: 14,
        degreeTrm2: 0,
      },
      {
        id: 8,
        stdName: "مريم عبدالحليم",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 9,
        stdName: "نورا محمد صالح حسين الشامي",
        degreeTrm1: 12,
        degreeTrm2: 21,
      },
      {
        id: 10,
        stdName: "دانه محسن الكثيري",
        degreeTrm1: 18,
        degreeTrm2: 19,
      },
      {
        id: 11,
        stdName: "اروى محمود محمد",
        degreeTrm1: 24,
        degreeTrm2: 21,
      },
      {
        id: 12,
        stdName: "رغد اسامة محمود الشيخ صالح",
        degreeTrm1: 20,
        degreeTrm2: 0,
      },
      {
        id: 13,
        stdName: "سمية عبدالفتاح عبادي عاطف عوض",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 38,
    schoolName: "زبيدة",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "ياسمين عمرو مصطفى",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: "كنزي مؤمن محمد",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: "نور ياسر القصير",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: "هاجر محمد بن حسين الحصايري",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: "هاجر أحمد طركزي",
        degreeTrm1: 24,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 39,
    schoolName: "طارق بن زياد",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "يوسف صفوت محمود",
        degreeTrm1: 26,
        degreeTrm2: 0,
      },
      {
        id: 2,
        stdName: "أحمد معاذ أحمد محمد",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: "بشار امجد محمد علي شعار",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 4,
        stdName: "قصي مهند صبحي أبولبادة",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 5,
        stdName: "عمار محمدعماد المنزلجي",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: "حليم ابراهيم حليم ابراهيم العطيوي",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 7,
        stdName: " لؤي حسام سليمان حسن سليمان",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 8,
        stdName: "محمد أسامة محمد نجيب سحلو",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 9,
        stdName: "محمد احمد محمد شوقي",
        degreeTrm1: 30,
        degreeTrm2: 28,
      },
      {
        id: 10,
        stdName: "إياد أحمد عبد الفتاح عبد العليم أبو العينين",
        degreeTrm1: 28,
        degreeTrm2: 25,
      },
      {
        id: 11,
        stdName: "محمد احمد محمد شوقي",
        degreeTrm1: 0,
        degreeTrm2: 22,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 40,
    schoolName: "علي بن جاسم ",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "أحمد رامي أحمد عبد الحافظ حمد",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 2,
        stdName: "عبدالله عاشور عبدالله علي",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: "وضاح بخيت ابراهيم سلمة زين",
        degreeTrm1: 28,
        degreeTrm2: 22,
      },
      {
        id: 4,
        stdName: "حسام",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 41,
    schoolName: "عمر بن عبدالعزيز",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "علي اياد ابويوسف",
        degreeTrm1: 26,
        degreeTrm2: 19,
      },
      {
        id: 2,
        stdName: "عبدالله محمد سعيد حردان",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: " سارية طلحت صويص",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 4,
        stdName: "محمد وسام محمد صلاح بركات",
        degreeTrm1: 28,
        degreeTrm2: 24,
      },
      {
        id: 5,
        stdName: "حازم يونس محمود أحمد",
        degreeTrm1: 24,
        degreeTrm2: 17,
      },
      {
        id: 6,
        stdName: "جهاد بالهادي بالقاسم غانم",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 7,
        stdName: "عمر محمد رياض الذويبي",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 8,
        stdName: "مصطفى وليد حسن",
        degreeTrm1: 30,
        degreeTrm2: 20,
      },
      {
        id: 9,
        stdName: "احمد محمد احمد علي",
        degreeTrm1: 30,
        degreeTrm2: 25,
      },
      {
        id: 10,
        stdName: "احمدهيثم",
        degreeTrm1: 22,
        degreeTrm2: 20,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 42,
    schoolName: "قطر",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "لين علي عبد الغني",
        degreeTrm1: 22,
        degreeTrm2: 11,
      },
      {
        id: 2,
        stdName: "نور محمود فرحات",
        degreeTrm1: 14,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: "عائشة غانم الكواري",
        degreeTrm1: 12,
        degreeTrm2: 23,
      },
      {
        id: 4,
        stdName: "سميه محمد فتيني زبل",
        degreeTrm1: 22,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: "بشاير الشمري",
        degreeTrm1: 20,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: "Yara hani mushtaha",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 7,
        stdName: "هند محمد سعيد فرج الكواري",
        degreeTrm1: 30,
        degreeTrm2: 10,
      },
      {
        id: 8,
        stdName: "مريم رائد",
        degreeTrm1: 28,
        degreeTrm2: 11,
      },
      {
        id: 9,
        stdName: "جمانة مصطفى سعد عبدالرحيم أبوالعز",
        degreeTrm1: 28,
        degreeTrm2: 3,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 43,
    schoolName: "مصعب بن عمير",
    learningType: "علمي",
    gender: "بنين",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "محمد أحمد محمود عبدالباقي",
        degreeTrm1: 28,
        degreeTrm2: 24,
      },
      {
        id: 2,
        stdName: "محمد احمد البدوي احمد العتر",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 3,
        stdName: "صهيب محمد سعداوي",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 4,
        stdName: "محمد ماهر نجم",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 5,
        stdName: "عمر سيد ربيع سيد",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 6,
        stdName: "محمد محمود محمد عبدالعزيز",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 7,
        stdName: "أنس صلاح جبر يوسف",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 8,
        stdName: "محمود محمد ابراهيم محمد عامر",
        degreeTrm1: 30,
        degreeTrm2: 27,
      },
      {
        id: 9,
        stdName: "عبدالكريم خالد خلف الحسبان",
        degreeTrm1: 30,
        degreeTrm2: 24,
      },
      {
        id: 10,
        stdName: "مصعب محمد الاسمر",
        degreeTrm1: 0,
        degreeTrm2: 15,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 44,
    schoolName: "ميسعيد المشتركة للبنات",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "ايلين ديمير",
        degreeTrm1: 28,
        degreeTrm2: 19,
      },
      {
        id: 2,
        stdName: "لين عبدالله",
        degreeTrm1: 28,
        degreeTrm2: 0,
      },
      {
        id: 3,
        stdName: "جويرية انس الخطيب",
        degreeTrm1: 28,
        degreeTrm2: 19,
      },
      {
        id: 4,
        stdName: "زين محمد نماس",
        degreeTrm1: 28,
        degreeTrm2: 19,
      },
      {
        id: 5,
        stdName: "نور جلال أسعد",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
  {
    id: 45,
    schoolName: "هند بنت ابي سفيان",
    learningType: "علمي",
    gender: "بنات",
    studCount: 0,
    degreeAvg: 0,
    weightRation: 0,
    stdsDetails: [
      {
        id: 1,
        stdName: "هنادي عبدالناصر زيد الاصحبي اليافعي",
        degreeTrm1: 22,
        degreeTrm2: 20,
      },
      {
        id: 2,
        stdName: "صيده سعيد",
        degreeTrm1: 20,
        degreeTrm2: 18,
      },
      {
        id: 3,
        stdName: "غدير محمد الحصوة",
        degreeTrm1: 16,
        degreeTrm2: 20,
      },
      {
        id: 4,
        stdName: "رناد عبدالله السفران",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 5,
        stdName: "الدانه حمد راشد المري",
        degreeTrm1: 30,
        degreeTrm2: 0,
      },
      {
        id: 6,
        stdName: "امرينة مارخيل",
        degreeTrm1: 30,
        degreeTrm2: 20,
      },
      {
        id: 7,
        stdName: "لانا أمين المصطفى",
        degreeTrm1: 26,
        degreeTrm2: 20,
      },
    ],
    teacherDetails: [
      {
        id: 1,
        teacherName: "محمد",
        stdNoSupervise: 99,
        jobTitle: "معلم كيمياء",
      },
    ],
  },
]

export { yearData, monthData, weekData, latestTransaction, schoolData }
