import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"

import { Badge, Button, Card, CardBody } from "reactstrap"
import { getOrders as onGetOrders } from "store/actions"

import EcommerceOrdersModal from "../Ecommerce/EcommerceOrders/EcommerceOrdersModal"
import { latestTransaction, schoolData } from "../../common/data/dashboard"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./LatestTranactionCol"

import TableContainer from "../../components/Common/TableContainer"
import StudentModal from "./modal/StudentModal"
import TeacherModal from "./modal/TeacherModal"

const LatestTranaction = ({handleSchoolNewData}) => {
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [studentData, setStudentData] = useState("")
  const [teacherData, setTeacherData] = useState("")
  const [schoolNewData, setSchoolNewData] = useState([])

  const toggleViewStdModal = () => setModal1(!modal1)
  const toggleViewTeacherModal = () => setModal2(!modal2)

  const columns = useMemo(
    () => [
      {
        Header: "مسلسل",
        accessor: "id",
        filterable: true,
        disableFilters: false,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "اسم المدرسة",
        accessor: "schoolName",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          // return <BillingName {...cellProps} />;
          return (
            <>
              {cellProps.row.original.gender === "بنين" ? (
                <Badge className="font-size-11 badge-soft-primary">
                  {cellProps.value}
                </Badge>
              ) : (
                <Badge className="font-size-11 badge-soft-pink">
                  {cellProps.value}
                </Badge>
              )}
            </>
          )
        },
      },
      {
        Header: "تقنى/ علمى",
        accessor: "learningType",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "بنين / بنات",
        accessor: "gender",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return <Total {...cellProps} />
        },
      },
      {
        Header: "عدد الطلاب المشاركين",
        accessor: "studCount",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "متوسط الدرجات",
        accessor: "degreeAvg",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return <PaymentMethod {...cellProps} />
        },
      },
      {
        Header: "الوزن النسبى",
        accessor: "weightRation",
        disableFilters: false,
        filterable: true,
        Cell: cellProps => {
          return <PaymentMethod {...cellProps} />
        },
      },
      {
        Header: "عرض الطلاب",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="success"
              className="btn-sm btn-rounded btn-p"
              onClick={e => {
                toggleViewStdModal()
                setStudentData(cellProps.row.original)
              }}
            >
              عرض الطلاب
            </Button>
          )
        },
      },
      {
        Header: "عرض المعلمين",
        disableFilters: true,
        accessor: "view2",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded btn-p"
              onClick={e => {
                toggleViewTeacherModal()
                setTeacherData(cellProps.row.original)
              }}
            >
              عرض المعلمين
            </Button>
          )
        },
      },
    ],
    []
  )

  // prepare school data
  useEffect(() => {
    if (schoolData.length > 0) {
      const newSchoolData = schoolData.map(item => {
        // add student count to school table
        item.studCount = item.stdsDetails.length

        // add degree avg to student table
        item.stdsDetails.map(
          std =>
            std.degreeTrm1 !== "-" &&
            std.degreeTrm2 !== "-" &&
            (std.avg = (std.degreeTrm1 + std.degreeTrm2) / 2)
        )
        item.stdsDetails.map(
          std =>
            std.degreeTrm1 === "-" &&
            std.degreeTrm2 !== "-" &&
            (std.avg = std.degreeTrm2 / 2)
        )
        item.stdsDetails.map(
          std =>
            std.degreeTrm1 !== "-" &&
            std.degreeTrm2 === "-" &&
            (std.avg = std.degreeTrm1 / 2)
        )

        // add degree avg to school table
        // This code add all student degree for exam1 + exam2 division by student count

        // let totalDegreePerSchool = 0
        // item.stdsDetails.map(std =>
        //   std.degreeTrm1 !== "-" && std.degreeTrm2 !== "-"
        //     ? (totalDegreePerSchool =
        //         totalDegreePerSchool + std.degreeTrm1 + std.degreeTrm2)
        //     : std.degreeTrm1 !== "-" && std.degreeTrm2 === "-"
        //     ? (totalDegreePerSchool = totalDegreePerSchool + std.degreeTrm1)
        //     : std.degreeTrm1 === "-" &&
        //       std.degreeTrm2 !== "-" &&
        //       (totalDegreePerSchool = totalDegreePerSchool + std.degreeTrm2)
        // )
        // const degreeAvg = (totalDegreePerSchool / item.stdsDetails.length).toFixed(2);
        // item.degreeAvg = degreeAvg
        

        // here calculate school avg by adding student avg then division by studend count
        let totalDegreePerSchool = 0
        item.stdsDetails.map(std => (totalDegreePerSchool = totalDegreePerSchool + std.avg))
        item.totalDegreePerSchool = totalDegreePerSchool;
        const degreeAvg = (totalDegreePerSchool / item.stdsDetails.length).toFixed(2);
        item.degreeAvg = degreeAvg

        // to calculate weight ratio
        item.weightRation = (item.stdsDetails.length * degreeAvg).toFixed(2)
        return item
      })
      const data = [...newSchoolData].sort((a, b) => (b.id - a.id));
      setSchoolNewData(data)
    }
  }, [schoolData])

  useEffect(() => {
    handleSchoolNewData(schoolNewData)
  }, [schoolNewData])

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <StudentModal
        isOpen={modal1}
        toggle={toggleViewStdModal}
        studentData={studentData}
      />
      <TeacherModal
        isOpen={modal2}
        toggle={toggleViewTeacherModal}
        teacherData={teacherData}
      />
      <Card>
        <CardBody dir="rtl">
          <div className="mb-4 h4 card-title">بيانات المدارس</div>
          <TableContainer
            columns={columns}
            data={schoolNewData}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
            allListSize={schoolData.length}
            customPageSizeOptions={2}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

LatestTranaction.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(LatestTranaction)
