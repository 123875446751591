import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import CertificateModal from "./CertificateModal"

const StudentModal = props => {
  const { isOpen, toggle, studentData } = props
  // Extract the base path from the resolved URL
  const [certModal, setCertModal] = useState(false)
  const [currStdData, setCurrStdData] = useState("")
  const [studentsData, setStudentsData] = useState([])

  const columns = useMemo(
    () => [
      {
        Header: "مسلسل",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "اسم الطالب",
        accessor: "stdName",
        disableFilters: true,
        Cell: cellProps => {
          // return <BillingName {...cellProps} />;
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "درجه الاختبار الاول",
        accessor: "degreeTrm1",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "درجة الاختبار الثاني",
        accessor: "degreeTrm2",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "المتوسط",
        accessor: "avg",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "عرض الشهاده",
        accessor: "certificate",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              className="btn-sm btn-p bg-soft bg-primary text-primary border-0"
              onClick={() => {
                setCurrStdData(cellProps.row.original)
                toggleViewCertModal()
              }}
              rel="noreferrer"
              target="_blank"
            >
              <i className="mdi mdi-eye-outline"></i>
            </Button>
          )
        },
      },
    ],
    []
  )

  const toggleViewCertModal = () => {
    setCertModal(!certModal)
  }

  useEffect(() => {
    if (studentData !== "") {
      const data = [...studentData.stdsDetails].sort((a, b) => b.id - a.id)
      setStudentsData(data)
    }
  }, [studentData])


  return (
    <Modal isOpen={isOpen} size="xl" toggle={toggle}>
      <CertificateModal
        isOpen={certModal}
        toggle={toggleViewCertModal}
        studentData={currStdData}
        allSchoolData={studentData}
      />
      <div className="modal-content" dir="rtl">
        <ModalHeader
          toggle={toggle}
        >{`مدرسة ${studentData.schoolName} / ${studentData.learningType} / ${studentData.gender}`}</ModalHeader>
        <ModalBody>
          <TableContainer
            columns={columns}
            data={studentsData}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={5}
            allListSize={
              studentData !== "" ? studentData.stdsDetails.length : []
            }
            customPageSizeOptions={2}
          />
        </ModalBody>
        {/* <Moda>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter> */}
      </div>
    </Modal>
  )
}

export default StudentModal
