import TableContainer from "components/Common/TableContainer"
import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import CertificateModal from "./CertificateModal"

const TeacherModal = props => {
  const { isOpen, toggle, teacherData } = props
  // Extract the base path from the resolved URL
  const [currTeacherData, setCurrTeacherData] = useState("")
  const [certModal, setCertModal] = useState(false)
  const [certFor, setCertFor] = useState("")

  const columns = useMemo(
    () => [
      {
        Header: "مسلسل",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "اسم المعلم / المعلمه",
        accessor: "teacherName",
        disableFilters: true,
        Cell: cellProps => {
          // return <BillingName {...cellProps} />;
          return (
            <>{cellProps.value}</>
          )
        },
      },
      {
        Header: "المسمي الوظيفي",
        accessor: "jobTitle",
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value}</>
        },
      },
      {
        Header: "عرض الشهاده",
        accessor: "certificate",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Button
              type="button"
              className="btn-sm btn-p bg-soft bg-primary text-primary border-0"
              onClick={() => {
                setCurrTeacherData(cellProps.row.original)
                console.log(cellProps.row.original)
                toggleViewCertModal()
                setCertFor("teacher")
              }}
              rel="noreferrer"
              target="_blank"
            >
              <i className="mdi mdi-eye-outline"></i>
            </Button>
          )
        },
      },
      
    ],
    []
  )

  const toggleViewCertModal = () => {
    setCertModal(!certModal)
  }
  return (
    <Modal isOpen={isOpen} size="xl" toggle={toggle}>
      <CertificateModal
        isOpen={certModal}
        toggle={toggleViewCertModal}
        studentData={currTeacherData}
        allSchoolData={teacherData}
        certFor={certFor}
      />
      <div className="modal-content" dir="rtl">
        <ModalHeader toggle={toggle}>{`مدرسة ${teacherData.schoolName} / ${teacherData.learningType} / ${teacherData.gender}`}</ModalHeader>
        <ModalBody>
          <TableContainer
            columns={columns}
            data={teacherData.teacherDetails}
            isGlobalFilter={true}
            isAddOptions={false}
            customPageSize={5}
            allListSize={teacherData !== "" ? teacherData.teacherDetails.length : []}
            customPageSizeOptions={2}
          />
        </ModalBody>
        {/* <Moda>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter> */}
      </div>
    </Modal>
  )
}

export default TeacherModal
