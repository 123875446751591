import React from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import ApexRadial from "./ApexRadial";

const MonthlyEarning = ({schoolNewData} ) => {
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-end">متوسط الدرجات الكلي للمدارس</CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">المعلمين / المعلمات</p>
              <h3>-</h3>
              <p className="text-muted">
                <span className="text-success me-2">
                  {" "}
                  -
                  {/* <i className="mdi mdi-arrow-up"></i>{" "} */}
                </span>{" "}
                الموجهين
              </p>
              <p className="text-muted">
                <span className="text-success me-2">
                  {" "}
                  - 
                  {/* <i className="mdi mdi-arrow-up"></i>{" "} */}
                </span>{" "}
                واضعي الامتحان
              </p>
              {/* <div className="mt-4">
                <Link
                  to="#"
                  className="btn btn-primary waves-effect waves-light btn-sm"
                >
                  View More <i className="mdi mdi-arrow-right ms-1"></i>
                </Link>
              </div> */}
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ApexRadial dataColors='["--bs-primary"]' schoolNewData={schoolNewData}  />
              </div>
            </Col>
          </Row>
          {/* <p className="text-muted mb-0">
            We craft digital, graphic and dimensional thinking.
          </p> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
